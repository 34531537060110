export default {
  Sheet1: [
    {
      Name: "Assunpink Winter Field",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Birds in Winter Field",
      Size: '18" x 24"',
      Year: "2017",
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Winter Landscape",
    },
    {
      Name: "Empty Box Creek Winter Sunrise",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Empty Box Creek Winter Sunset",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Assunpink Winter Field, Early Thaw",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Snowy Field with Golden Trees",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Entrance to Field",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Field with Fence",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Farm Field",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Sunset on Snowy Fields",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Farm",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Farm Field 2",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Path to Field",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Winter Road, Sunset",
      Size: '11" X 14"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Winter Landscape",
    },
    {
      Name: "Aunt Tullie's Living Room",
      Size: '11" X 14" mat',
      Year: "2016",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Director's Office",
      Size: '11" X 14" mat',
      Year: "2016",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "English Living Room",
      Size: '14" X 11" mat',
      Year: "2016",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Favorite Restaurant in Italy",
      Size: '11" X 14" mat',
      Year: "2016",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Harry's Enclosed Porch",
      Size: '11" X 14" mat',
      Year: "2016",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Harry's Living Room",
      Size: '14" X 11" mat',
      Year: "2016",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Lydia's for Thanksgiving",
      Size: '22" x 28" mat',
      Year: "2017",
      Material: "Acrylic on paper",
      "Cost unframed": "850",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Museum Court Yard",
      Size: '11" X 14" mat',
      Year: "2017",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "The Professor's Front Hall",
      Size: '11" X 14" mat',
      Year: "2015",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "The Professor's Study",
      Size: '14" X 11" mat',
      Year: "2015",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "The Professor's Unattached Porch",
      Size: '14" X 11" mat',
      Year: "2017",
      Material: "Acrylic on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Tea Time at the Professor's",
      Size: '14" X 11" mat',
      Year: "2015",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "The Professor's Interiors",
    },
    {
      Name: "Salt Marsh, Birds",
      Size: '18" X 24"',
      Year: "2017",
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Salt Marsh",
    },
    {
      Name: "Salt Marsh, September",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Salt Marsh",
    },
    {
      Name: "Salt Marsh, November",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Salt Marsh",
    },
    {
      Name: "Salt Marsh, November 2",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Salt Marsh",
    },
    {
      Name: "Salt Marsh, November 3",
      Size: '11" X 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Salt Marsh",
    },
    {
      Name: "Stone in the Middle of the Road, Split in Two",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, In the Woods",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, From Both Sides",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, As the Leaves Fall",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, It Grows On You",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, Could Not Stop Nature",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, Diverts the Path",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, As a Bridge",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "Stone in the Middle of the Road, Split in Two, a Different View",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name:
        "Stone in the Middle of the Road, It is the Small Things That Make a Difference",
      Size: '10" x 10"',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Stone in the Middle of the Road",
    },
    {
      Name: "A R Wallace, Listening to a Tuba Player",
      Size: '11" X 14" mat',
      Year: "2006 - 2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Advice for the Queen",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Amelia's Pearls",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Cat's Spirit",
      Size: '11" X 14" mat',
      Year: "2006 - 2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Cezanne and Hippos, Near Caravan",
      Size: '11" X 14" mat',
      Year: "2006 - 2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Children, Gloves, and Animals",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Choosing a Tea Pot",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Contemplating the Universe",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Desert Nights",
      Size: '11" x 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Ellis Island",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Farmers",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Feeding the Animals",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Fishing for God",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Flight",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Frog Legs",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Full Moon",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Head of a Girl",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Horse Races on the Steppes",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "In Flight",
      Size: '11" x 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "In the Jungle",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "In the Tent",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Island Archeology",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "It is Spring",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Italian Sunglasses",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Japenese Physicist",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Judy's Grand Daughter",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Listening",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Market Place",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Midnight in the Garden of Art and Science",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Naptime",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Nightmare at the Bayou",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Observing Closely",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Ponds Edge",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Questions",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Recital",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Resting",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "School Children",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Seated Model",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Silk Road",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "St John's Pond",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Staying a Float",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Armchair Traveler",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Cosmic Car Theory",
      Size: '11"  X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Dance",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Hall",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Library",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Parade",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Road to the Market",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Tall Tale",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "The Waiting Room",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "They Could Not Be Caught",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "They Talked of Religion",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "They Thought About the Day Over Coffee",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "They Waited",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Travel Picture",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Under the Bridge",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Under the Island",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Pencil on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "While he Sat his Thoughts Wondered",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Wrestlers",
      Size: '11" X 14" mat',
      Year: "2006-2008",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Drawing",
    },
    {
      Name: "Chet 1",
      Size: '8" x 10" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "140",
      Category: "Cold River Camp",
    },
    {
      Name: "Chet 2",
      Size: '8" x 10" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "140",
      Category: "Cold River Camp",
    },
    {
      Name: "Chet 3",
      Size: '8" x 10" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "140",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Field",
      Size: '8" x 10" mat',
      Year: "2008",
      Material: "Acrylic on paper",
      "Cost unframed": "140",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Library, Night",
      Size: '11" X 14" mat',
      Year: "2010",
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Porch",
      Size: '11" X 14" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Cabin",
      Size: '11" X 14" mat',
      Year: "2013",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Library 1",
      Size: '11" X 14" mat',
      Year: "2010",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Library 2",
      Size: '11" X 14" mat',
      Year: "2010",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Porch 2",
      Size: '11" X 14" mat',
      Year: "2014",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Cold River Vatican City",
      Size: '11" X 14" mat',
      Year: "2014",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Path to Emerald Pool",
      Size: '18" x 24"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "1000",
      Category: "Cold River Camp",
    },
    {
      Name: "Tea House at Cold River",
      Size: '11" X 14" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Cold River Camp",
    },
    {
      Name: "Spring Haven Interior",
      Size: '11" X 14" mat',
      Year: "2013",
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Cold River Camp",
    },
    {
      Name: "Spring Haven Exterior",
      Size: '11" x 14"  mat',
      Year: "2013",
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Cold River Camp",
    },
    {
      Name: "Bear",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Boomer",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "Animals",
    },
    {
      Name: "Boomer 2",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "Animals",
    },
    {
      Name: "Cat",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Cats",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Chipmunks",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Chipmunks 2",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Deer at Sunset",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Deer in Woods",
      Size: '10" x 8" mat',
      Year: "2012",
      Material: "Pastels",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Deer",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Dogs",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Fox",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Foxes",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Lily",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "Animals",
    },
    {
      Name: "Rabbit",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Rabbit 2",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Rabbit 2a",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Rabbit 3",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Raccoon",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Raccoon 2",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Spirit of a Cat",
      Size: '10" x 8" mat',
      Year: "2001",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Squirrel",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "Stag",
      Size: '10" x 8" mat',
      Year: "2011",
      Material: "Acrylic",
      "Cost unframed": "50",
      Category: "Animals",
    },
    {
      Name: "7 13 09",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 13 09 2",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 13 09 3",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 16 09",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 16 09 Evening",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 23 09",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 24 09 Morning",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 24 09 Sunset",
      Size: '10" x 8" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "School Doors",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sea Isle City Sunrise",
      Size: '11" x 14" mat',
      Year: "2015",
      Material: "Watercolor on paper",
      "Cost unframed": "300",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 I",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 II",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 III",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 IV",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 IX",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 V",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 VI",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 VII",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 VIII",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 X",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 XI",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "Sunrise, Sunset 2008 XII",
      Size: '10" x 8" mat',
      Year: "2008",
      Material: "Watercolor on paper",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "C1",
      Size: '11" x 14"',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Clay Print 2",
      Size: '11" x 14" mat',
      Year: "2008",
      Material: "Clay",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Clay Print 3",
      Size: '11" x 14" mat',
      Year: "2008",
      Material: "Clay",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Clay Print with Felting",
      Size: '11" x 14" mat',
      Year: "2008",
      Material: "Clay and Wool",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Day of the Dead Freda Portrait",
      Size: '8" x 10"   ',
      Year: "2016",
      Material: "Acrylic",
      "Cost unframed": "150",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Day of the Dead",
      Size: '8" x 12"',
      Year: "2008",
      Material: "Oil",
      "Cost unframed": "150",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Fairies Sleeping",
      Size: '11" x 14"',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "300",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Illuminated Writing 2",
      Size: '8" x 10" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Illuminated Writing 3",
      Size: '8" x 10" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Illuminated Writing 4",
      Size: '8" x 10" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Illuminated Writing 5",
      Size: '8" x 10" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Illuminated Writing 6",
      Size: '8" x 10" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "50",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Post Card Intersection",
      Size: '5" x 7"',
      Year: "2010",
      Material: "Mixmedia",
      "Cost unframed": "sold",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Nature's Bath",
      Size: '18" x 24" mat',
      Year: "2009",
      Material: "Pastels",
      "Cost unframed": "800",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "The Source",
      Size: '11" x 14" ',
      Year: "2015",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Beach Scene 2",
      Size: '11" x 14" mat',
      Year: "2010",
      Material: "Watercolor on paper",
      "Cost unframed": "300",
      Category: "Beach",
    },
    {
      Name: "Belmar Beach",
      Size: '11" x 14" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "300",
      Category: "Beach",
    },
    {
      Name: "Boats",
      Size: '11" x 14" mat',
      Year: "1975",
      Material: "Graphite",
      "Cost unframed": "75",
      Category: "Beach",
    },
    {
      Name: "Island State Park Beach",
      Size: '11" x 14" mat',
      Year: "2010",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Mystic Connecticut, Boats",
      Size: '11" x 14 mat',
      Year: "1999",
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Mystic Connecticut Inlet",
      Size: '11" x 14" mat',
      Year: "1999",
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Ocean City Houses",
      Size: '11" x 14" mat',
      Year: "2014",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Ocean City Sunrise",
      Size: '11" X 14" mat',
      Year: "2014",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Sunbathers",
      Size: '11" X 14" mat',
      Year: "2009",
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Beach",
    },
    {
      Name: "Bone",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 13",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 14",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 15",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 16",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 17",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 18",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series 19",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series XX",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Ink on paper",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Bone Series XXI",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Figure Study 1",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Figure Study 2",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Figure Study 3",
      Size: '11" x 14" mat',
      Year: "1977",
      Material: "Mixmedia",
      "Cost unframed": "75",
      Category: "Bone",
    },
    {
      Name: "Assunpink Fall Weeds",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Assunpink Summer Fields",
      Size: '11" X 14"',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Assunpink Stone Tavern Lake",
      Size: '18"  X 24"',
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Field and Lakes",
    },
    {
      Name: "Back Pasture, San Antonio",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Brown Field",
      Size: '11" x 14"',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Camp Fire",
      Size: '10" x 10"',
      Material: "acrylic",
      "Cost unframed": "260",
      Category: "Field and Lakes",
    },
    {
      Name: "Camp Fire 2",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Field and Lakes",
    },
    {
      Name: "Camp Fire 3",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Field and Lakes",
    },
    {
      Name: "Clay Print of Leaves",
      Size: '11" x 14"',
      Material: "Clay",
      "Cost unframed": "75",
      Category: "Field and Lakes",
    },
    {
      Name: "Delaware Water Gap, Fall Field",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Dorsey's Fishing Place",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Field and Lakes",
    },
    {
      Name: "Early Summer Corn Field",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Early Fall Meadow",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Field in Early Morning Light",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Haddonfield Bridge",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "San Antonio Aqueduct",
      Size: '24" x 15.5"',
      Material: "Acrylic",
      "Cost unframed": "1000",
      Category: "Field and Lakes",
    },
    {
      Name: "Millstone Watershed Pond",
      Size: '7" x 9"',
      Material: "Pastels",
      "Cost unframed": "90",
      Category: "Field and Lakes",
    },
    {
      Name: "NJ Farm House",
      Size: '7" x 9"',
      Material: "Pastels",
      "Cost unframed": "90",
      Category: "Field and Lakes",
    },
    {
      Name: "NJ Farm",
      Size: '7" x 9"',
      Material: "Pastels",
      "Cost unframed": "90",
      Category: "Field and Lakes",
    },
    {
      Name: "St Mary's Co. Barn",
      Size: '8" x 10" mat',
      Year: "1976",
      Material: "Watercolor on paper",
      "Cost unframed": "100",
      Category: "Field and Lakes",
    },
    {
      Name: "Terhune Orchard, Apple Trees",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "Terhune Orchard, Cherries",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Field and Lakes",
    },
    {
      Name: "A Walk in the Woods",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Delaware Water Gap, Stream",
      Size: '18" x 24"',
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Forest Path",
    },
    {
      Name: "Fall Mountain Stream",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Forest Bathing",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Grid Landscape",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Hiking in the Mountains",
      Size: '8" X 10" Mat',
      Material: "Watercolor on paper",
      "Cost unframed": "275",
      Category: "Forest Path",
    },
    {
      Name: "Mountain Falls",
      Size: '8" x 10"',
      Material: "Acrylic",
      "Cost unframed": "275",
      Category: "Forest Path",
    },
    {
      Name: "My November Guest",
      Size: '8" x 10"',
      Material: "Acrylic",
      "Cost unframed": "275",
      Category: "Forest Path",
    },
    {
      Name: "NJ Stream",
      Size: '11" x 14" mat',
      Year: "2000",
      Material: "Pastels",
      "Cost unframed": "300",
      Category: "Forest Path",
    },
    {
      Name: "NJ Woods",
      Size: '11" x 14" mat',
      Year: "2001",
      Material: "Pastels",
      "Cost unframed": "300",
      Category: "Forest Path",
    },
    {
      Name: "North NJ Park Bridge",
      Size: '11" x 14" mat',
      Year: "2000",
      Material: "Pastels",
      "Cost unframed": "300",
      Category: "Forest Path",
    },
    {
      Name: "Printed Landscape",
      Size: '11" x 14"',
      Material: "Print",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "River Reflections",
      Size: '11" x 14"',
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Roosevelt Woods, Late Afternoon",
      Size: '18" x 24"',
      Material: "Acrylic",
      "Cost unframed": "1000",
      Category: "Forest Path",
    },
    {
      Name: "Roosevelt Woods",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Rutgers Garden Woods",
      Size: '8" x 10"',
      Material: "Acrylic",
      "Cost unframed": "300",
      Category: "Forest Path",
    },
    {
      Name: "Summer Greens",
      Size: '11" x 14"',
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Sunrise Mountain",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "The Encounter in the Woods",
      Size: '11" x 14" mat',
      Material: "Pastels",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Tillman Ravine Fall Stream",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Tillman Ravine Fall Stream 2",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Tillman Ravine Fall Stream 3",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Tillman Ravine Fall Stream 4",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Tillman Ravine Fall Stream 5",
      Size: '10" x 10"',
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Two Roads Divided in a Yellow Woods",
      Size: '18" X 24"',
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Forest Path",
    },
    {
      Name: "Winter Woods",
      Size: '8" x 10" mat',
      Material: "Mixmedia",
      "Cost unframed": "100",
      Category: "Forest Path",
    },
    {
      Name: "Woodland Path",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Forest Path",
    },
    {
      Name: "Delaware Water Gap Stream 2",
      Size: '18" x 24"',
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "Forest Path",
    },
    {
      Name: "Sunset Pikes Peak",
      Size: '11" x 14" mat',
      Material: "Colored Pencil",
      "Cost unframed": "50",
      Category: "Sunrise, Sunset Series",
    },
    {
      Name: "7 16 09",
      Size: '8" x 10" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "275",
      Category: "Gardens",
    },
    {
      Name: "Brookside Path",
      Size: '7" x 9" mat',
      Material: "pastels",
      "Cost unframed": "175",
      Category: "Gardens",
    },
    {
      Name: "Garden Path",
      Size: '8" x 10" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "300",
      Category: "Gardens",
    },
    {
      Name: "Garden Tour Painting",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "400",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden 2",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden 3",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden 4",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden 5",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kirk and Susan's Garden 6",
      Size: '11" x 14" mat',
      Material: "Watercolor on paper",
      "Cost unframed": "sold",
      Category: "Gardens",
    },
    {
      Name: "Kuser Mansion",
      Size: '11" X 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Gardens",
    },
    {
      Name: "Kuser Mansion 2",
      Size: '11" x 14"',
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Gardens",
    },
    {
      Name: "Beach Sunset",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "275",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "On the Edge of a Pond",
      Size: '11" x 14"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Abstract and Fantasy",
    },
    {
      Name: "Abstract Forest Path",
      Size: '11" x 14"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "400",
      Category: "Acrylic Collage",
    },
    {
      Name: "Ducks in Salt Marsh",
      Size: '20" x 20"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "700",
      Category: "Acrylic Collage",
    },
    {
      Name: "Garden Path",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "275",
      Category: "Acrylic Collage",
    },
    {
      Name: "Pierced Sun",
      Size: '20" x 20"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "700",
      Category: "Acrylic Collage",
    },
    {
      Name: "Winter Deer",
      Size: '20" x 20"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "700",
      Category: "Acrylic Collage",
    },
    {
      Name: "Woodland Stream",
      Size: '20" x 20"',
      Year: "2019",
      Material: "Acrylic and paper",
      "Cost unframed": "700",
      Category: "Acrylic Collage",
    },
    {
      Name: "Black Bill Magpies",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Cows in Overcast Field",
      Size: '11" x 14"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Animals",
    },
    {
      Name: "Cows Near Hay Bales",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Cows Near Trees",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Eastern Box Turtle",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Herd of Deer in Field",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Herd of Deer in the Woods",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Nebraska Cows",
      Size: '12" x 12"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "375",
      Category: "Animals",
    },
    {
      Name: "Sparrows",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Birds on the Edge of a Brush Pile",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Animals",
    },
    {
      Name: "Squirrel Eating an Apple",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Wild Burros",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Animals",
    },
    {
      Name: "Custer Park Buffalo",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Antelope",
      Size: '5" x 7"   ',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "Animals",
    },
    {
      Name: "Red and Blue Cows",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "Animals",
    },
    {
      Name: "Turkeys",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "Animals",
    },
    {
      Name: "Moss on New Hampshire Rock",
      Size: '10" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Forest Path",
    },
    {
      Name: "Path to Emerald Pool 2",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "500",
      Category: "Forest Path",
    },
    {
      Name: "Ellarslie Period Room",
      Size: '22" x 28" mat',
      Year: "2019",
      Material: "Watercolor on paper",
      "Cost unframed": "800",
      Category: "Other Interiors",
    },
    {
      Name: "Trent House Chairs",
      Size: '6" x 7" mat',
      Year: "2019",
      Material: "Watercolor",
      "Cost unframed": "90",
      Category: "Other Interiors",
    },
    {
      Name: "Trent House Bedroom",
      Size: '6" x 7" mat',
      Year: "2019",
      Material: "Watercolor",
      "Cost unframed": "90",
      Category: "Other Interiors",
    },
    {
      Name: "Yale Library",
      Size: '11" x 14" mat',
      Year: "2019",
      Material: "Watercolor",
      "Cost unframed": "400",
      Category: "Other Interiors",
    },
    {
      Name: "Beach Sunset",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Painting on the Edge",
    },
    {
      Name: "Birds on the Edge of a Brush Pile",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Painting on the Edge",
    },
    {
      Name: "Ducks on the Edge of the Pond",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Painting on the Edge",
    },
    {
      Name: "On the Edge of a Pond",
      Size: '11" x 14"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "Painting on the Edge",
    },
    {
      Name: "South Dakota Trout Stream",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Painting on the Edge",
    },
    {
      Name: "The Road Between the Fields",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "Painting on the Edge",
    },
    {
      Name: "Bob Burkey",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Bob Burkey's Dad and Step Mom",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Bob Burkey's Father",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Bob Burkey's Father 2",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Bob Burkey's Father 3",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Dad Laughing on the Deck",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "People",
    },
    {
      Name: "Mom 4",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "People",
    },
    {
      Name: "Mom 5",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "People",
    },
    {
      Name: "Mom Laughing",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "People",
    },
    {
      Name: "The Burkey Men",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "People",
    },
    {
      Name: "Devil's Tower",
      Size: '20" x 20"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "1030",
      Category: "South Dakota Trip",
    },
    {
      Name: "Nebraska Cows",
      Size: '12" x 12" ',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "370",
      Category: "South Dakota Trip",
    },
    {
      Name: "Antelope",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Black Hills Waterfall",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "South Dakota Trip",
    },
    {
      Name: "Buffalo",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Custer Park Buffalo",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Field and Devil's Tower",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Cows in Overcast Field",
      Size: '11" x 14"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "400",
      Category: "South Dakota Trip",
    },
    {
      Name: "Red and Blue Cows",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Turkeys",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "sold",
      Category: "South Dakota Trip",
    },
    {
      Name: "View from Corkscrew Road",
      Size: '8" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "210",
      Category: "South Dakota Trip",
    },
    {
      Name: "Black Bill Magpies",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "90",
      Category: "South Dakota Trip",
    },
    {
      Name: "Hackberry",
      Size: '10" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Trees",
    },
    {
      Name: "Live Oak",
      Size: '10" x 10"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "260",
      Category: "Trees",
    },
    {
      Name: "Mom and Dad on the Deck",
      Size: '12" x 16"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "500",
      Category: "People",
    },
    {
      Name: "Mom and Dad's Wedding Still",
      Size: '18" x 24"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "People",
    },
    {
      Name: "Mom and Dad's Wedding Still 2",
      Size: '18" x 24"',
      Year: "2019",
      Material: "Acrylic",
      "Cost unframed": "1110",
      Category: "People",
    },
    {
      Name: "Mom and Dad's Wedding 1",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylc",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 2",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 3",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 4",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 5",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 6",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 7",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 8",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 9",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 10",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 11",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 12",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 13",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 14",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 15",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 16",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad's Wedding 17",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad's Wedding Movie",
    },
    {
      Name: "Mom and Dad Dancing 1",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 2",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 3",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 4",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 5",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 6",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 7",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 8",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 9",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 10",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 11",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 12",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 13",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 14",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 15",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 16",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 17",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 18",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 19",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom and Dad Dancing 20",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom and Dad Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 1",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 2",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 3",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 4",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 5",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 6",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 7",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 8",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 9",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 10",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 11",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 12",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 13",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 14",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 15",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 16",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 17",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 18",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 19",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 20",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Mom Dancing 21",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Mom Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 1",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 2",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 3",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 4",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 5",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 6",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 7",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 8",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 9",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 10",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 11",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 12",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 13",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 14",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 15",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 16",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 17",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 18",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Dad Dancing 19",
      Size: '5" x 7"',
      Year: "2019",
      Material: "Acrylic",
      Category: "Dad Dancing, the Movie",
    },
    {
      Name: "Mike's Waterfall on the AT",
      Size: '10 1/2" x 13 1/2"',
      Year: "2020",
      "Cost unframed": 400,
      Material: "Mono Print",
      Category: "Forest Path",
    },
    {
      Name: "The Tiled Hallway",
      Size: '22" x 28"',
      Year: "2020",
      "Cost unframed": 1100,
      Material: "Watercolor",
      Category: "The Professor's Interiors",
    },
  ],
};
